import React from 'react';
import Link from "next/link";

function FeedPost({post}) {
	return (
		<div className="">
			<Link className="mb-5 block" href={process.env.NEXT_PUBLIC_BLOG_URL + '/' + post.slug}>
				<figure className="m-0" style={{
					paddingTop: '56.25%',
					backgroundImage: `url('${post.image}')`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center'}}></figure>
			</Link>
			<div className="">
				<h3 className="text-2xl mb-2">
					<Link className="" href={process.env.NEXT_PUBLIC_BLOG_URL + '/' + post.slug}>{post.title}</Link>
				</h3>
				
				<div className="mb-4">
					<Link className="" href={process.env.NEXT_PUBLIC_BLOG_URL + '/' + post.slug}>by <b>{post.author_name}</b></Link>
					<span className="">&nbsp;·&nbsp;</span>
					<span className="">{post.est_reading_time}</span>
				</div>
				
				<Link href={process.env.NEXT_PUBLIC_BLOG_URL + '/' + post.slug} className="d-inline-block" style={{outline: 'none',boxshadow: 'none'}}>
					{post.preview_text}
				</Link>
				
				<div className="mt-1">
					<ul className="d-inline m-0 p-0" style={{fontSize:'.75rem'}}>
						<li className="list-unstyled d-inline">
							{post.tags.map((tag) =>
								<Link
									className="badge badge-info  p-1 d-inline-block"
									href={'/tags/' + tag.slug.en}
									style={{color:'white',outline: 'none',boxShadow: 'none',borderRadius: '9999px'}}
								>{tag.slug.en}</Link>
							)}
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default FeedPost;
