import { apiSlice } from "./apiSlice"

const apiWithTag = apiSlice.enhanceEndpoints({addTagTypes: ['Blog', 'Posts']})

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getAllBlogPosts: build.query({
			query: () => ({ url: '/blog/all', method:'get'}),
		}),
		getBlogPost: build.query({
			query: id => ({ url: '/blog/post/' + id, method:'get'}),
		}),
		getBlogPostsByTag: build.query({
			query: tag => ({ url: '/blog/tag/' + tag, method:'get'}),
		}),
		getBlogPostsByCategory: build.query({
			query: category => ({ url: '/blog/category/' + category, method:'get'})
		}),
	}),
	overrideExisting: false,
})

export const {
	// general
	useGetAllBlogPostsQuery,
	useGetBlogPostQuery,
	useGetBlogPostsByTagQuery,
	useGetBlogPostsByCategoryQuery,
	
	util: { getRunningQueriesThunk, resetApiState, getState }
	
} = extendedApi

// export endpoints for use in SSR
export const {
	getAllBlogPosts,
	getBlogPost,
	getBlogPostsByTag,
	getBlogPostsByCategory,
} = extendedApi.endpoints;
