import React from 'react';
import Layout from "../components/Layout";
import {wrapper} from "../store";
import FeedPost from "../components/FeedPost";
import {useGetAllBlogPostsQuery, getRunningQueriesThunk, getAllBlogPosts} from "../rtk-api/blog";
import {NextSeo} from "next-seo";

export const getServerSideProps = wrapper.getServerSideProps(store => async ({req, params}) => {
	
	store.dispatch(getAllBlogPosts.initiate());
	await Promise.all(store.dispatch(getRunningQueriesThunk()));
	
	return {
		props: {},
	};
});

function Index() {
	const {data} = useGetAllBlogPostsQuery();
	
	return (
		<Layout>
			<section className="px-5 pb-20">
				<div className="container">
					<h1 className="heading text-3xl mb-5">PageName Blog</h1>
					
					<div className="md:grid md:grid-cols-2">
						{ data?.posts.map((post, index) =>
							<FeedPost post={post} key={index} />
						)}
					</div>
				</div>
			</section>
		</Layout>
	);
}

export default Index;
